import React, { useState, useEffect, useRef } from 'react';
import {
    ValidationForm,
    Grid,
    Cell,
    FieldText,
    Btn,
    Container,
    Box,
    PanelKeys,
    H2,
    Type,
} from '@saladbob/sassafras'

import toast from 'react-hot-toast';

import Link from 'next/link';
import { useRouter } from 'next/router';

import login from '../crud/login'
import { fetchUser } from '../hooks/useSwr';

import { User } from '../types';

type Props = {
    onLogin?: Function,
};

const LogIn: React.FC<Props> = ({ onLogin }) => {
    const { user } = fetchUser();
    const router = useRouter();
    const [step, setStep] = useState(null);
    const isSubmitting = useRef(false);
    const [disabled, setDisabled] = useState(false);
    const { redirect } = router.query;

    useEffect(() => {
        setDisabled(isSubmitting.current);
    }, [isSubmitting]);

    const onSubmit = async (form, data) => {
        if (user || isSubmitting.current) {
            return;
        };

        const res: Promise<User> = new Promise(async (resolve, reject) => {
            isSubmitting.current = true;
            const r = await login(data);

            if (r.errors) {
                reject(r.errors[0]);
            } else {
                resolve(r.user);
            }
        });
        toast.promise(res, {
            loading: 'Logging in...',
            success: (r) => {
                onLogin(r);
                isSubmitting.current = false;
                return 'Logged in!';
            },
            error: (err) => {
                setStep('login');
                isSubmitting.current = false;
                return err.message;
            },
        });
    };

    return (
        <Container maxWidth="480px">
            <ValidationForm key="login" onSubmit={onSubmit}>
                <H2 align="center" mb="lg">LOGIN</H2>
                <Grid bgColor="white" columns="1fr" gridGap="lg" mb="md">
                    <Cell>
                        <FieldText
                            font="leader"
                            size="lg"
                            type="email"
                            placeholder="Email"
                            name="email"
                            rules={{ email: true, required: true,  }}
                            bgColor="neutral50"
                            bdWidth="0"
                        />
                    </Cell>
                    <Cell>
                        <FieldText
                            placeholder="Password"
                            type="password"
                            name="password"
                            font="leader"
                            size="lg"
                            bgColor="neutral50"
                            bdWidth="0"
                            rules={{ required: true }}
                        />
                    </Cell>
                    <Cell>
                        <Btn disabled={disabled} type="submit" color={disabled ? 'neutral' : 'secondary'} size="lg" font="leader" full mb="lg">LOGIN</Btn>
                        <Box display="flex" justify="space-around" txtAlign="center">
                            <Type font="p" tag="p" align="center" mr="md" color="secondary">
                                <Link href={`/create-account${redirect ? `?redirect=${redirect}` : ''}`}>
                                    Create Account
                                </Link>
                            </Type>
                            <Type font="p" tag="p" align="center" color="secondary">
                                <Link href="/forgot">
                                    Forgot Password?
                                </Link>
                            </Type>
                        </Box>
                    </Cell>
                </Grid>
            </ValidationForm>
        </Container>
    );
}

LogIn.defaultProps = {
    onLogin: () => {},
};

export default LogIn;
import { User } from "../types";

type UserDocProps = {
    user: User,
    errors?: [{
        message: string
    }],
};

export default async function saveUser({ email, password }: { email: string, password: string }, ): Promise<UserDocProps> {
    try {
        const req = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/user/login`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, password }),
        })
        const data = await req.json();
        return data;
    } catch (err) {
        return err;
    }
}
import React, { useEffect } from 'react';
import {
    Box,
} from '@saladbob/sassafras'

import { useRouter } from 'next/router';

import { useEnv } from '../../components/providers/EnvProvider';
import { fetchUser } from '../../hooks/useSwr';

import getSite from '../../crud/getSite';
import LogIn from '../../components/LogIn';
import Layout from '../../components/Layout';
import type { NextPageWithLayout, PageProps } from '../_app';

type Props = {
    Header: React.FC,
};

export async function getStaticProps() {
    const site = await getSite();

    return {
        props: {
            ...site,
        },
    }
}

const Page: NextPageWithLayout<Props> = () => {
    const { user, isLoading, mutate } = fetchUser();
    const router = useRouter();
    const env = useEnv();

    const onLogin = (user) => {
        mutate({ user }, {
            populateCache: true,
            revalidate: false,
        });
        // redirect();
    };

    const redirect = () => {
        const routePath = Array.isArray(router.query.redirect)
            ? decodeURI(router.query.redirect[0])
            : router.query.redirect ? decodeURI(router.query.redirect) : '/dashboard';
        router.push(routePath);
    };

    useEffect(() => {
        if (!isLoading && user?.id) {
            redirect();
        }
    }, [user]);

    return (
        <Box bgColor="white" padding="md" minHeight={`calc(100vh - ${env.headerHeight}px)`} verticalAlign="center">
            <LogIn onLogin={onLogin}/>
        </Box>
    );
};

Page.getLayout = function getLayout(page: React.ReactElement, pageProps: PageProps ) {
    return (
        <Layout { ...pageProps }>
            {page}
        </Layout>
    )
}

export default Page;